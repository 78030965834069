import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ErrorPage from 'screens/Error'

const RootNav = () => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/error" component={ErrorPage} />
        <Route path="/" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default RootNav
