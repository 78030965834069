import React from 'react'
import { ReactComponent as SyncIcon } from '../../assets/images/sync.svg'

const ErrorPage: React.FC = () => {
  return (
    <div className="app">
      <h1 className="title">Oops! Try opening that email from your iPhone.</h1>
      <p className="subtitle text-large">
        The Optivio app is not supported on this device.
      </p>
      <SyncIcon className="sync-icon" />
      <p className="contact-us text-default">
        Have any questions? Email us at admin@optivio.com
      </p>
    </div>
  )
}

export default ErrorPage
